import React from "react";

const HeroImage = ({ image }) => {
  return (
    <div className="hero">
      <img src={image} alt="Naslovna Fotografija" className="hero-image" />
    </div>
  );
};

export default HeroImage;
